// import http from "./http"
import api from "./api"
const localGenerateBaseUrl=""
// import { localGenerateBaseUrl } from "@/assets/js/Global"

export const login = data => api.postFormAPI(data, "/v1.0/resource/user/login")

export const logout = data => api.getListAPI(data, "/v1.0/resource/user/logout")
export const queryDetailDevice = data => api.getListAPI(data, "/v1.0/resource/resource/queryDetailDevice")
export const genReportView = (data, params) => api.postFormAPI(data, localGenerateBaseUrl + "/sync/genReportView", params)
//分页查询
export const queryPageDevice = data => api.postFormAPI(data, "/v1.0/resource/resource/queryPageDevice")

//查询仪器设备统计
export const queryDeviceStateCount = data => api.postFormAPI(data, "/v1.0/resource/resource/queryDeviceStateCount")

//删除设备
export const deleteDetailDevice = data => api.getListAPI(data, "/v1.0/resource/resource/deleteDevice")

//修改编辑设备
export const insertDeviceDetailDevice = data => api.postFormAPI(data, "/v1.0/resource/resource/insertDevice")

//导出模板下载--标准/设备
export const DownLoadFile = (data, params) => api.getListAPI(data, "/v1.0/resource/resource/download", params)
//pdf 预览
export const previewReport = (data, params) => api.getListAPI(data, "/v1.0/resource/resource/previewReport", params)
//导入excel数据
export const ImportFile = data => api.postFormAPI(data, "/v1.0/resource/resource/templateImport")
export const queryPageTemplatePic = data => api.postFormAPI(data, "/v1.0/resource/resource/queryPageTemplatePic")
export const saveOrUpdateTemplatePic = data => api.postFormAPI(data, "/v1.0/resource/resource/saveOrUpdateTemplatePic")
export const uploadProjectImage = data => api.postFormAPI(data, "/v1.0/resource/file/uploadProjectImage")
export const delTemplatePic = data => api.getListAPI(data, "/v1.0/resource/resource/delTemplatePic")
export const queryPageTemplatePicPos = data => api.postFormAPI(data, "/v1.0/resource/resource/queryPageTemplatePicPos")
export const saveOrUpdateTemplatePicPos = data => api.postFormAPI(data, "/v1.0/resource/resource/saveOrUpdateTemplatePicPos")
export const delTemplatePicPos = data => api.getListAPI(data, "/v1.0/resource/resource/delTemplatePicPos")
//》》》》》》》》》》》》》》角色管理
//角色组
export const queryRoleGroupList = data => api.postLoginAPI(data, "/role/queryRoleGroupList")
//查询角色
export const queryRoleList = data => api.getLoginAPI(data, "/role/queryRoleList")
//查询管理员
export const queryAdminRoleList = data => api.getLoginAPI(data, "/role/queryAdminRoleList")
//统计
export const statistics = data => api.getLoginAPI(data, "/role/statistics")
//删除角色
export const delRole = data => api.getLoginAPI(data, "/role/delRole")
//删除角色组
export const delRoleGroup = data => api.getLoginAPI(data, "/role/delRoleGroup")
//新建or编辑
export const addOrUpdateRoleGroupt = data => api.postLoginAPI(data, "/role/addOrUpdateRoleGroup")
export const addOrUpdateRole = data => api.postLoginAPI(data, "/role/addOrUpdateRole")
//查询用户
export const queryAccount = data => api.postLoginAPI(data, "/role/queryAccount")
//添加角色
export const addMembers = data => api.postLoginAPI(data, "/role/addMembers")
//分配角色
export const setMembers = data => api.postLoginAPI(data, "/role/setting")

//》》》》》》》》》》》》企业信息
export const getCompanyInfo = data => api.getLoginAPI(data, "/company/getCompanyInfo")
//修改
export const updateCompanyInfo = data => api.postLoginAPI(data, "/company/updateCompanyInfo")

//》》》》》》》》》》》》发票信息

//获取
export const getInvoiceInfo = data => api.getLoginAPI(data, "/tenant/getInvoiceInfo")
//edit
export const updateInvoiceInfo = data => api.postLoginAPI(data, "/tenant/updateInvoiceInfo")
/**获取租户下订阅产品信息 */
export const getSubscribeProduct = data => api.getLoginAPI(data, "/tenant/getSubscribeProduct")

//判断文件是否存在
export const checkFileExistence = data => api.getListAPI(data, "/v1.0/resource/file/checkFileExistence")

//实验室资质
//add or edit
export const testOrgAdd = data => api.postFormAPI(data, "/v1.0/resource/testOrg/insertOrUpdateTestOrg")
//delete
export const testOrgDelete = data => api.getListAPI(data, "/v1.0/resource/testOrg/deleteTestOrg")
//search
export const testOrgSearch = data => api.getListAPI(data, "/v1.0/resource/testOrg/queryDetailsTestOrg")
//page
export const testOrgPage = data => api.postFormAPI(data, "/v1.0/resource/testOrg/queryPageTestOrg")
export const testOrgStatistics = data => api.postFormAPI(data, "/v1.0/resource/testOrg/queryPageStatistics")

//分页查询
export const queryPageStandard = (data, params) => api.postFormAPI(data, "/v1.0/resource/resource/queryPageStandard", params)

// 新增标准规范修改标准规范
export const insertOrUpdateStandard = data => api.postFormAPI(data, "/v1.0/resource/resource/insertOrUpdateStandard")
//标准详情
export const queryDetailPageStandard = data => api.getListAPI(data, "/v1.0/resource/resource/queryDetailPageStandard")

//删除标准
export const deleteStandard = data => api.getListAPI(data, "/v1.0/resource/resource/deleteStandard")

//查询标准规范统计
export const queryStandardCount = data => api.postFormAPI(data, "/v1.0/resource/resource/queryStandardCount")

///////////////////人员检测
//查询
export const queryStaffInfo = data => api.postFormAPI(data, "/v1.0/resource/staffInfo/queryStaffInfo")
//新建
export const saveStaff = data => api.postFormAPI(data, "/v1.0/resource/staffInfo/saveStaff")
//编辑
export const modifyStaffInfo = data => api.postFormAPI(data, "/v1.0/resource/staffInfo/modifyStaffInfo")
//新建证书
export const saveStaffCert = data => api.postFormAPI(data, "/v1.0/resource/staffCert/saveStaffCert")
//删除
export const deleteStaffInfo = data => api.getListAPI(data, "/v1.0/resource/staffInfo/deleteStaffInfo")
//查询证书
export const queryStaffCert = data => api.postFormAPI(data, "/v1.0/resource/staffCert/queryStaffCert")
//删除证书
export const deleteStaffCert = data => api.getListAPI(data, "/v1.0/resource/staffCert/deleteStaffCert")
//修改
export const modifyStaffCert = data => api.postFormAPI(data, "/v1.0/resource/staffCert/modifyStaffCert")

//全局任务查看
//获取生成池列表数据
export const queryPageReportPool = data => api.postFormAPIResource(data, "/resource/queryPageReportPool")
//获取生成池详情
export const queryPageReportDetails = data => api.postFormAPIResource(data, "/resource/queryPageReportDetails")
//生成失败回调
export const callbackForError = data => api.postFormAPIResource(data, "/resource/callbackForError")

// 查询检测对象
export const queryPageDetectionObject = data => api.postFormAPI(data, "/v1.0/resource/resource/queryPageDetectionObject")
export const queryTaskPageTemplatePic = (data, params) => api.postFormAPI(data, "/v1.0/resource/task/queryTaskPageTemplatePic", params)
export const delTokenProjectPic = data => api.getListAPI(data, "/v1.0/resource/task/delTokenProjectPic")
export const downloadReport = (data, params) => api.getListAPI(data, "/v1.0/resource/task/downloadReport", params)

export const saveOrUpdateTokenProjectPic = data => api.postFormAPI(data, "/v1.0/resource/task/saveOrUpdateTokenProjectPic")
export const queryTestRecordModifyLog = data => api.postFormAPI(data, "/v1.0/resource/task/queryTestRecordModifyLog")

// 查询试验项目
export const queryPageTestingItem = (data, params) => api.postFormAPI(data, "/v1.0/resource/resource/queryPageTestingItem", params)

// 模板查询
export const queryModel = data => api.postFormAPI(data, "/v1.0/resource/resource/queryModel")


//查询试验项目详情
export const queryDeviceTestingItem = data => api.getListAPI(data, "/v1.0/resource/resource/queryDeviceTestingItem")

// 文件上传
export const upload = data => api.postFormAPI(data, "/v1.0/resource/file/upload", "", { headers: { "Content-Type": "multipart/form-data" } })

// 添加模板
export const insertModel = data => api.postFormAPI(data, "/v1.0/resource/resource/insertModel")

// 查询方案
export const queryScheme = data => api.getListAPI(data, "/v1.0/resource/task/queryScheme")
// 查询用户
export const queryUser = data => api.getListAPI(data, "/v1.0/resource/task/queryUser")

// 统计任务状态
export const taskStateStatistics = (
    data = {
        pageNum: 1,
        pageSize: 9999,
        filters: [
            {
                fieldName: "state",
                operator: "EQ",
                fieldValue: 0,
                groupName: "_o",
            },
        ],
        sorters: [
            {
                sortField: "createTime",
                direction: "ASC",
            },
        ],
    }
) => api.postFormAPI(data, "/v1.0/resource/taskV2/taskStateStatistics")

// 校验抽检编号是否被占用
export const testScTaskCode = data => api.getListAPI(data, "/v1.0/resource/taskV2/testScTaskCode")
/**委托编号校验 */
export const testEntrustNo = data => api.getListAPI(data, "/v1.0/resource/taskV2/testEntrustNo")

// 新建任务NO1
export const insertScheme = data => api.postFormAPI(data, "/v1.0/resource/task/insertScheme")
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>改版任务
// 新建任务NO2
export const insertORUpdateTask = data => api.postFormAPI(data, "/v1.0/resource/taskV2/insertORUpdateTask")
// 分页查询任务V2
export const queryPageTaskV = data =>
    api.postFormAPI(
        {
            ...data,
            filters: [
                ...data.filters,
                // {
                //     fieldName: "orgId",
                //     operator: "IN",
                //     connectOperator: "AND",
                //     fieldValue: store.state.userInfo.orgId.split(","),
                //     groupName: "_W",
                // },
            ],
        },
        "/v1.0/resource/taskV2/queryPageTask"
    )
// 主界面查询任务详情
export const queryDetailsTaskV = data => api.getListAPI(data, "/v1.0/resource/taskV2/queryDetailsTask")


// 新建样品
export const insertSample = data => api.postFormAPI(data, "/v1.0/resource/task/insertSample")
// 新增检测与项目之间的关系
export const insertRelation = data => api.postFormAPI(data, "/v1.0/resource/task/insertRelation")

// 分页查询任务
export const queryPageTask = data =>
    api.postFormAPI(
        {
            ...data,
            filters: [
                ...data.filters,
                // {
                //     fieldName: "orgId",
                //     operator: "IN",
                //     connectOperator: "AND",
                //     fieldValue: store.state.userInfo.orgId.split(","),
                //     groupName: "_W",
                // },
            ],
        },
        "/v1.0/resource/task/queryPageTask"
    )

// 复制任务
export const copyTask = data => api.postFormAPI(data, "/v1.0/resource/taskV2/copyTask")

// 查询项目下的详情
export const queryTaskDetails = data => api.getListAPI(data, "/v1.0/resource/taskV2/queryTaskDetails")

// 查询项目下的详情
export const queryProjectDetails = data => api.getListAPI(data, "/v1.0/resource/taskV2/queryProjectDetails")

// 项目与设备的关系维护
export const queryProjectAndDevice = data => api.getListAPI(data, "/v1.0/resource/taskV2/queryProjectAndDevice")

// 项目与样品的关系维护
export const queryProjectAndSample = data => api.getListAPI(data, "/v1.0/resource/taskV2/queryProjectAndSample")

// 抽检结论统计
export const testingItemsStateStatistics = data => api.getListAPI(data, "/v1.0/resource/taskV2/testingItemsStateStatistics")

// 项目与设备的关系维护
export const projectAndDevice = data => api.postFormAPI(data, "/v1.0/resource/taskV2/projectAndDevice")

// 项目与样品的关系维护
export const projectAndSample = data => api.postFormAPI(data, "/v1.0/resource/taskV2/projectAndSample")
// 主界面查询任务详情
export const queryDetailsTask = data => api.getListAPI(data, "/v1.0/resource/task/queryDetailsTask")

// 项目基本信息修改
export const updateBasicInfo = data => api.postFormAPI(data, "/v1.0/resource/taskV2/updateBasicInfo")

// 试验条件 恢复默认数据
export const recoverTestConditions = data => api.getListAPI(data, "/v1.0/resource/taskV2/testConditionsSetDefault")

// 分页查询检测对象(列表)
export const queryPageDetectionObjectList = data => api.postFormAPI(data, "/v1.0/resource/resource/queryPageDetectionObjectList")
// 查询委托单位
export const queryClient = data => api.postFormAPI(data, "/v1.0/resource/task/queryClient")

// 查询用户所在机构和部门
export const queryUserOrg = data => api.postFormAPI(data, "/v1.0/resource/task/queryUserOrg")
// 修改检测结论
export const updateConclusion = data => api.postFormAPI(data, "/v1.0/resource/taskV2/updateConclusion")

// 查询方案
export const schemeQueryScheme = data => api.getListAPI(data, "/v1.0/resource/scheme/queryScheme")
// 新增或修改方案
export const insertOrUpdateScheme = data => api.postFormAPI(data, "/v1.0/resource/scheme/insertOrUpdateScheme")

// 新增或修改方案
export const deleteScheme = data => api.getListAPI(data, "/v1.0/resource/scheme/deleteScheme")
// 查询方案详情
export const querySchemeDetails = data => api.getListAPI(data, "/v1.0/resource/scheme/querySchemeDetails")
// 查询已经配置的设备
export const queryConfigDevice = data => api.getListAPI(data, "/v1.0/resource/resource/queryConfigDevice")

// 查询已经配置的标准
export const queryConfigStandard = data => api.getListAPI(data, "/v1.0/resource/resource/queryConfigStandard")

// 配置设备
export const configDevice = data => api.postFormAPI(data, "/v1.0/resource/resource/configDevice")
// 配置标准
export const configStandard = data => api.postFormAPI(data, "/v1.0/resource/resource/configStandard")
// 删除对象
export const deleteObject = data => api.getListAPI(data, "/v1.0/resource/resource/deleteObject")
// 删除项目
export const deleteProject = data => api.getListAPI(data, "/v1.0/resource/resource/deleteProject")
// 新增或修改对象
export const insertOrUpdateObject = data => api.postFormAPI(data, "/v1.0/resource/resource/insertOrUpdateObject")

export const insertOrUpdateProject = data => api.postFormAPI(data, "/v1.0/resource/resource/insertOrUpdateProject")
// 禁用模板
export const disableTemplate = data => api.getListAPI(data, "/v1.0/resource/resource/disableTemplate")

// 修改默认模板
export const updateDefaultTemplate = data => api.getListAPI(data, "/v1.0/resource/resource/updateDefaultTemplate")

// 录入数据之前 拉取模板文件到当前任务下
export const dataEntry = data => api.postFormAPI(data, "/v1.0/resource/taskV2/dataEntry")

// 录入数据 将试验条件存到模板指定位置
export const templatePretreatment = data => api.getListAPI(data, "/v1.0/resource/task/templatePretreatment")

// 适用于本任务下的所有项目
export const applyAll = data => api.getListAPI(data, "/v1.0/resource/taskV2/applyAll")

// 获取附录
export const getUploadAttachedPage = data => api.postFormAPI(data, "/v1.0/resource/report/queryAttachedPage")

// 删除附录
export const deleteAttachedPage = data => api.postFormAPI(data, "/v1.0/resource/report/deleteAttachedPage")

// 上传附页
export const reportUploadAttachedPage = data => api.postFormAPI(data, "/v1.0/resource/report/reportUploadAttachedPage", "", { headers: { "Content-Type": "multipart/form-data" } })

// 报告生成
export const reportGen = (data, params) => api.postFormAPI(data, localGenerateBaseUrl + "/sync/genReport", params)
// 获取报告生成状态

export const callbackReport = data => api.postFormAPI(data, "/v1.0/resource/resource/callbackReport")
export const getReportServe = data => api.postFormAPI(data, "/v1.0/resource/resource/callbackOrig")
export const getReportGenSate = data => api.getListAPI(data, "/v1.0/resource/report/getReportGenSate")

// 回显原始记录编号
export const getEchoOrigReportNo = data => api.getListAPI(data, "/v1.0/resource/orig/getEchoOrigReportNo")

// 原始记录生成
export const origReportGen = (data, params) => api.postFormAPI(data, localGenerateBaseUrl + "/sync/genOrig", params)

// 获取原始记录生成状态

export const getOrigReportGenSate = data => api.getListAPI(data, "/v1.0/resource/orig/getOrigReportGenSate")

// 下载报告
export const getReportFile = (data, config) => api.getListAPI(data, "/v1.0/resource/report/getReportFile", config)

// 报告查看
export const reportLook = data => api.getListAPI(data, "/v1.0/resource/report/reportLook")

// 报告删除
// /report/deleteReport?reportId=047E82327F2D42F3BCAA2240E4620F27
export const reportDelete = (data, params) => api.postFormAPI(data, "/v1.0/resource/report/deleteReport", params)

// 查询报告及获取报告详情
export const queryReport = data => api.postFormAPI(data, "/v1.0/resource/report/queryReport")

// 原始记录查看
export const origReportLook = data => api.getListAPI(data, "/v1.0/resource/orig/origReportLook")

// 原始记录删除
// /orig/deleteOrigReport?origReportId=4614D1369287411A8CB3940D64A637C3
export const oriReportDelete = (data, params) => api.postFormAPI(data, "/v1.0/resource/orig/deleteOrigReport", params)

//查询原始记录及获取原始记录详情
export const queryOrigReport = data => api.postFormAPI(data, "/v1.0/resource/orig/queryOrigReport")

/* -------------------通用模板--------------------------- */

// 查询通用模板
export const queryNonTemplate = data => api.postFormAPI(data, "/v1.0/resource/nonTemplate/queryNonTemplate")

// 删除通用模板
export const deleteNonTemplate = data => api.postFormAPI(data, "/v1.0/resource/nonTemplate/deleteNonTemplate")

// 上传通用模板
export const createNonTemplate = data => api.postFormAPI(data, "/v1.0/resource/nonTemplate/createNonTemplate", "", { headers: { "Content-Type": "multipart/form-data" } })

/* -------------------组织结构--------------------------- */

// 查询组织机构
export const queryOrg = data => api.postLoginAPI(data, "/tenant/queryOrg")

// 新建组织机构
export const createOrg = data => api.postLoginAPI(data, "/tenant/createOrg")

// 编辑组织机构
export const modifyOrg = (data, orgId) => api.postLoginAPI(data, "/tenant/modifyOrg?orgId=" + orgId)

// 删除组织机构
export const deleteOrg = data => api.postLoginAPI(data, "/tenant/deleteOrg")

/* -------------------用户管理--------------------------- */

// 查询用户

export const userQueryUser = data => api.postLoginAPI(data, "/tenant/queryUser")

// 获取用户详情
export const getUser = data => api.getListAPI(data, "/v1.0/resource/user/getUser")

// 新建用户
export const createUser = data => api.postFormAPI(data, "/v1.0/resource/user/createUser")

// 删除用户
export const deleteUser = data => api.postFormAPI(data, "/v1.0/resource/user/deleteUser")

// 编辑用户
export const modifyUser = (data, userId) => api.postFormAPI(data, "/v1.0/resource/user/modifyUser?userId=" + userId)

// 验证密码 多用于删除等操作
export const checkPassword = data => api.postFormAPI(data, "/v1.0/resource/user/checkPassword", "", { showErrorMessage: false })

/* -------------------系统级参数配置--------------------------- */
// 查询列表  系统级配置
export const querySysSetup = data => api.postFormAPI(data, "/v1.0/resource/sysSetup/querySysSetup")

// 获取详情  系统级配置
/**
 * @description 获取详情  系统级配置
 * @param {*} data query参数 { sysSetupId: String }
 * @returns promise
 */
export const getSysSetup = data => api.getListAPI(data, "/v1.0/resource/sysSetup/getSysSetup")

// 新建  系统级配置
export const createSysSetup = data => api.postFormAPI(data, "/v1.0/resource/sysSetup/createSysSetup")

// 编辑  系统级配置
/**
 * @description 修改  系统级配置
 * @param {*} data body参数
 * @param {*} params query参数 { sysSetupId: String }
 * @returns promise
 */
export const modifySysSetup = (data, params) => api.postFormAPI(data, "/v1.0/resource/sysSetup/modifySysSetup", params)

// 连接测试
/**
 * @description 连接测试
 * @param {*} data query参数 { sysSetupId: String }
 * @returns promise
 */
export const getServiceHealth = data => api.getListAPI(data, "/v1.0/resource/sysSetup/getServiceHealth")

// 删除  系统级配置
/**
 * @description 删除 系统级配置
 * @param {*} data 参数 { deleteSysSetupList: [ids] }
 * @returns promise
 */
export const deleteSysSetup = data => api.postFormAPI(data, "/v1.0/resource/sysSetup/deleteSysSetup")

export const queryPageReportWarehouse = (data, params) => api.postFormAPI(data, "/v1.0/resource/warehouse/queryPageReportWarehouse", params)

export const queryDetailsReportWarehouse = data => api.getListAPI(data, "/v1.0/resource/warehouse/queryDetailsReportWarehouse")

export const testNull = data => api.getListAPI(data, "/v1.0/resource/taskV2/testNull")

export const onlyRead = data => api.postFormAPI(data, "/v1.0/resource/warehouse/onlyRead")

export const queryPageAttachment = data => api.getListAPI(data, "/v1.0/resource/scheme/queryPageAttachment")

export const queryAppendixTemplate = data => api.postFormAPI(data, "/v1.0/resource/scheme/queryAppendixTemplate")

export const newAttachment = data => api.postFormAPI(data, "/v1.0/resource/scheme/newAttachment")

export const deleteAttachment = data => api.getListAPI(data, "/v1.0/resource/scheme/deleteAttachment")

export const heartbeat = data => api.getListAPI(data, "/v1.0/sync/heart/heartbeat")


export const checkDeleteUser = data => api.getListAPI(data, "/v1.0/resource/user/checkDeleteUser")

export const checkServer = data => api.postFormAPI(data, "/v1.0/resource/user/checkServer")

export const updateConclusionTest = data => api.postFormAPI(data, "/v1.0/resource/taskV2/updateConclusionTest")

export const queryHistory = data => api.getListAPI(data, "/v1.0/resource/warehouse/queryHistory")

export const queryDevice = data => api.getListAPI(data, "/v1.0/resource/taskV2/queryDevice")

export const getFileUrl = data => api.getListAPI(data, "/v1.0/resource/file/getFileUrl")

export const downloadForOss = data => api.getListAPI(data, "/v1.0/resource/file/downloadForOss", { responseType: "blob" })

export const reportGenSupplement = data => api.postFormAPI(data, "/v1.0/resource/task/reportGenSupplement")

export const saveAttachmentOrder = data => api.postFormAPI(data, "/v1.0/resource/scheme/saveAttachmentOrder")

export const queryPageTestOrg = data => api.postFormAPI(data, "/v1.0/resource/testOrg/queryPageTestOrg")

export const insertOrUpdateTestOrg = data => api.postFormAPI(data, "/v1.0/resource/testOrg/insertOrUpdateTestOrg")

export const deleteTestOrg = data => api.getListAPI(data, "/v1.0/resource/testOrg/deleteTestOrg")

export const queryDetailsTestOrg = data => api.getListAPI(data, "/v1.0/resource/testOrg/queryDetailsTestOrg")
// logo上传
export const uploadImage = data => api.postFormAPI(data, "/v1.0/resource/file/uploadOss", "", { headers: { "Content-Type": "multipart/form-data" } })

export const readLogoFile = params => api.postFormAPI({}, "/v1.0/resource/task/readLogoFile", params)

export const packUpload = (data = {}) => api.postFormAPI(data, "/v1.0/sync/sync/packUpload")

export const q = (data = {}) => api.postFormAPI(data, "/v1.0/sync/sync/q")

export const templateExport = data => api.postFormAPI(data, "/v1.0/resource/warehouse/templateExport", "", { responseType: "blob" })

export const DownCors = url => api.postFormAPI({}, url, "", { responseType: "blob" })

export const deleteOrRestoreTask = data => api.getListAPI(data, "/v1.0/resource/taskV2/deleteOrRestoreTask")

export const topping = data => api.getListAPI(data, "/v1.0/resource/taskV2/topping")

export const collect = data => api.getListAPI(data, "/v1.0/resource/taskV2/collect")

export const completelyDeleteTask = data => api.getListAPI(data, "/v1.0/resource/taskV2/completelyDeleteTask")

export const checkLicense = data => api.postFormAPI(data, "/v1.0/resource/user/checkLicense")

export const queryResult = data => api.getListAPI(data, "/v1.0/resource/task/queryResult")

export const saveResult = data => api.getListAPI(data, "/v1.0/resource/task/saveResult")

export const deleteResult = data => api.getListAPI(data, "/v1.0/resource/task/deleteResult")

export const queryTokenByProject = data => api.getListAPI(data, "/v1.0/resource/taskV2/queryTokenByProject")

export const queryPageReportFormat = data => api.postFormAPI(data, "/v1.0/resource/format/queryPageReportFormat")

export const saveReportFormat = data => api.postFormAPI(data, "/v1.0/resource/format/saveReportFormat")

export const delReportFormat = data => api.getListAPI(data, "/v1.0/resource/format/delReportFormat")

export const queryDetailsReportFormat = data => api.getListAPI(data, "/v1.0/resource/format/queryDetailsReportFormat")

export const releaseReportFormat = data => api.postFormAPI(data, "/v1.0/resource/format/releaseReportFormat")

export const saveReportFormatComponent = data => api.postFormAPI(data, "/v1.0/resource/format/saveReportFormatComponent")

export const queryPageReportFormatComponent = data => api.postFormAPI(data, "/v1.0/resource/format/queryPageReportFormatComponent")

export const queryPageReportFormatContent = data => api.postFormAPI(data, "/v1.0/resource/format/queryPageReportFormatContent")

export const saveReportFormatContent = data => api.postFormAPI(data, "/v1.0/resource/format/saveReportFormatContent")

export const delReportFormatContent = data => api.getListAPI(data, "/v1.0/resource/format/delReportFormatContent")

export const querySchemeFormat = data => api.getListAPI(data, "/v1.0/resource/scheme/querySchemeFormat")

export const saveSchemeFormat = data => api.postFormAPI(data, "/v1.0/resource/scheme/saveSchemeFormat")

export const queryAlreadySchemeFormat = data => api.getListAPI(data, "/v1.0/resource/scheme/queryAlreadySchemeFormat")

export const saveReportFormatGeneral = data => api.postFormAPI(data, "/v1.0/resource/format/saveReportFormatGeneral")

export const queryDetailsReportFormatGeneral = data => api.getListAPI(data, "/v1.0/resource/format/queryDetailsReportFormatGeneral")

export const queryDetailsReportFormatSequnce = data => api.getListAPI(data, "/v1.0/resource/format/queryDetailsReportFormatSequnce")

export const saveReportFormatSequnce = data => api.postFormAPI(data, "/v1.0/resource/format/saveReportFormatSequnce")

export const setDefaultPage = data => api.getListAPI(data, "/v1.0/resource/format/setDefaultPage")

export const setDefaultProject = data => api.getListAPI(data, "/v1.0/resource/format/setDefaultProject")

export const presetObjectPropertyAddStar = data => api.postFormAPI(data, "/v1.0/resource/meta/presetObjectPropertyAddStar")

export const presetObjectPropertyCancelAddStar = data => api.postFormAPI(data, "/v1.0/resource/meta/presetObjectPropertyCancelAddStar")

export const getReportGenTask = data => api.getListAPI(data, "/v1.0/resource/meta/getReportGenTask")

export const queryPresetObjectProperty = data => api.postFormAPI(data, "/v1.0/resource/meta/queryPresetObjectProperty")

export const queryTemplateGroup = data => api.postFormAPI(data, "/v1.0/resource/meta/queryTemplateGroup")

export const queryTemplateFileInfo = data => api.postFormAPI(data, "/v1.0/resource/meta/queryTemplateFileInfo")

export const createPresetObjectInfo = data => api.postFormAPI(data, "/v1.0/resource/meta/createPresetObjectInfo")

export const modifyPresetObjectInfo = data => api.postFormAPI(data, "/v1.0/resource/meta/modifyPresetObjectInfo")

export const modifyPresetObjectProperty = data => api.postFormAPI(data, "/v1.0/resource/meta/modifyPresetObjectProperty")

export const deletePresetObjectInfo = data => api.postFormAPI(data, "/v1.0/resource/meta/deletePresetObjectInfo")

export const queryPresetObjectInfo = data => api.postFormAPI(data, "/v1.0/resource/meta/queryPresetObjectInfo")

export const createPresetObjectProperty = data => api.postFormAPI(data, "/v1.0/resource/meta/createPresetObjectProperty")

export const deletePresetObjectProperty = data => api.postFormAPI(data, "/v1.0/resource/meta/deletePresetObjectProperty")

export const updatePresetObjectPropertyOrder = data => api.postFormAPI(data, "/v1.0/resource/meta/updatePresetObjectPropertyOrder")

export const updatePresetObjectInfoOrder = data => api.postFormAPI(data, "/v1.0/resource/meta/updatePresetObjectInfoOrder")

export const modifyReportSysSetup = data => api.postFormAPI(data, "/v1.0/resource/sysSetup/modifyReportSysSetup")

export const delReportFormatComponent = data => api.getListAPI(data, "/v1.0/resource/format/delReportFormatComponent")

export const saveReportComponentSetup = data => api.postFormAPI(data, "/v1.0/resource/format/saveReportComponentSetup")

export const queryPageReportComponentSetup = data => api.postFormAPI(data, "/v1.0/resource/format/queryPageReportComponentSetup")

export const delReportComponentSetup = data => api.getListAPI(data, "/v1.0/resource/format/delReportComponentSetup")

export const refreshAppendix = data => api.getListAPI(data, "/v1.0/resource/taskV2/refreshAppendix")

export const isAppendixLatest = data => api.getListAPI(data, "/v1.0/resource/taskV2/isAppendixLatest")

export const queryPrincipal = data => api.postFormAPI(data, "/v1.0/library/template/queryPrincipal")

export const ComplexTemplateConf = params => api.getListAPI(params, "/v1.0/library/template/ComplexTemplateConf")

export const queryPageComplexTemplate = data => api.postFormAPI(data, "/v1.0/library/template/queryPageComplexTemplate")

export const uploadOssForTemplateInfo = data => api.postFormAPI(data, "/v1.0/resource/file/uploadOssForTemplateInfo")

export const getHomeStatistics = () => api.postFormAPI("", "/v1.0/resource/resource/homeStatistics")


const devServer = '';
// const devServer = 'http://47.104.68.37:13012';
// const newServer="http://118.178.184.218:13012"
// const oldServer = 'http://47.104.68.37:13012';
/** 生成地址 开发环境为/api代理  生产环境为本地localhost */
const Server = "//" + window.location.hostname + ":"
const ifrServer=process.env.NODE_ENV === "development" ?  "http://47.104.68.37:13015" :  `http:${Server}13015`
const ifrServerLogout=process.env.NODE_ENV === "development" ? "http://47.104.68.37:13016/" : `http:${Server}13016/`
const localGenerateBaseUrl = process.env.NODE_ENV === "development" ? '/api' : 'http://localhost:23489';
const ssoServer = process.env.NODE_ENV === "development" ? devServer : devServer;
const WebsocketUrl = process.env.NODE_ENV === "development" ? 'ws://192.168.23.26:23489/webSocket' : 'ws://localhost:23489/webSocket';
/**报告生成 获取文件生成信息*/
const Websocket = process.env.NODE_ENV === "development" ? "ws://192.168.23.26:23489/webSocket" : "ws://localhost:23489/webSocket"
/**报告生成池信息获取 */
const WebsocketMessage = process.env.NODE_ENV === "development" ? "ws://192.168.23.26:26571/websocket" : `ws:${Server}13012/v1.0/resource/websocket`

export default {
    ssoServer,
}

export {
	/** 接口地址 */
    ssoServer,
	/** 报告生成地址 */
    localGenerateBaseUrl,
	/** websocket地址 */
    WebsocketUrl,
    ifrServerLogout,
    Websocket,
    WebsocketMessage,
    ifrServer
}

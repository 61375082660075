import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
import { isElectron, ipcRenderer, isDev } from "@/core/Electron"
import { setLocalToken, getLocalToken, removeLocalToken } from "@/core/token"
import { ifrServerLogout } from "@/api/global"
import {MessageBox} from "element-ui"
Vue.use(VueRouter)
//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush = VueRouter.prototype.push
import { ChangePassword, LoginPath, LoginName, Login, RegisterPath, RegisterName, Register, ChangePasswordPath, ChangePasswordName } from "@/core/index"

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果成功 调用原来的push方法
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        )
    }
}

import { constantLayoutRoutes } from "./config"

/** 登录 */
// const Login = () => import("@/views/Login")

/** 注册 */
// const Register = () => import("@/views/Register")

/** 网络无连接 路由组件 */
const Offline = () => import("@/views/Offline")

/** 首页路由地址 */
const HomePath = "/"

/** 网络无连接 路由地址 */
const OfflinePath = "/Offline"

/** 布局路由组件 */
const Layout = () => import("@/components/Layout")

const routes = [
    // 后台登录
    {
        path: LoginPath,
        name: LoginName,
        component: Login,
        // beforeEnter(to, from, next){
        //     // store.commit('setDocumentFrontTitle');
        //     next();
        // },
        meta: {
            title: "登录",
        },
    },
    {
        path: ChangePasswordPath,
        name: ChangePasswordName,
        component: ChangePassword,
        meta: {
            title: "修改密码",
            icon: "appHeaderAlert",
            showInSideMenu: true,
        },
    },
    {
        path: RegisterPath,
        name: RegisterName,
        component: Register,
        meta: {
            title: "注册",
        },
    },
    {
        path: OfflinePath,
        name: "Offline",
        component: Offline,
    },
    {
        path: "/",
        name: "Layout",
        redirect: "/workbench",
        component: Layout,
        // beforeEnter(to, from, next){
        //     console.log('LandingPage.beforeEnter');
        //     // store.commit('setDocumentFrontTitle');
        //     next();
        // },
        children: constantLayoutRoutes,
    },
    {
        path: "*",
        redirect: "/",
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

//判断用户是否登录
/** 是否为初次加载当前token对应用户信息 */
let fistLogin = true
// 当前环境为electron 本地token存在 且为生产模式 清除token
if (isElectron && getLocalToken() && !isDev) {
    removeLocalToken()
}
// 路由守卫
router.beforeEach(async (to, from, next) => {
    /** query传递的token值 多用于系统或门户之间跳转 */
    console.log("fistLogin",fistLogin);
    let queryToken = to.query.token
    // query传递的token值存在 设置token至本地
    if (queryToken) {
        store.commit("setToken", queryToken)
        store.commit("setMentWeb", true)
        let query = to.query
        delete query.token
        next({
            ...to,
            query,
        })
        return
    }
    // 当前环境为electron 本地token存在 且为初次加载 直接设置当前窗口token
    else if (isElectron && getLocalToken() && fistLogin && isDev) {
        setLocalToken(getLocalToken())
    }

    let loginPaths = [LoginPath, RegisterPath, ChangePasswordPath]
    let token = getLocalToken()
    // console.log("beforeEach", token, fistLogin, to.path, from.path)
    /** 需要重定向的路由地址 */
    let redirect

    // // 网络无连接  来源路由地址不是OfflinePath，此时需要跳转OfflinePath，并传入来源路由地址from.query.redirect或from.fullPath做redirect参数
    // if (!window.navigator.onLine && to.path === OfflinePath && !to.query.redirect && from.path !== OfflinePath){
    //     redirect = encodeURIComponent(from.fullPath);
    //     console.log('网络无连接, redirect=', redirect);
    //     next({
    //         path: OfflinePath,
    //         query: {
    //             redirect,
    //         },
    //         replace: true
    //     });
    // }
    // // 网络无连接  目标路由是OfflinePath，此时需要跳转OfflinePath，并传入目标路由做redirect参数
    // else if (!window.navigator.onLine && to.path !== OfflinePath && from.path === OfflinePath){
    //     next({
    //         path: from.fullPath,
    //         replace: true,
    //     });
    // }
    // else if (!window.navigator.onLine && to.path !== OfflinePath && from.path !== OfflinePath){
    //     redirect = encodeURIComponent(to.fullPath);
    //     console.log('网络无连接, redirect=', redirect);
    //     next({
    //         path: OfflinePath,
    //         query: {
    //             redirect,
    //         },
    //         replace: true
    //     });
    // }
    // else if (!window.navigator.onLine){
    //     next();
    // }
    // // 网络已连接 目标路由地址为OfflinePath，此时需要重定向
    // else if (to.path === OfflinePath){
    //     if (to.query.redirect){
    //         redirect = decodeURIComponent(decodeURIComponent(to.query.redirect));
    //     } else {
    //         redirect = '/';
    //     }
    //     console.log('网络已连接, redirect=', redirect, to.fullPath);
    //     next({
    //         path: redirect,
    //         replace: true
    //     })
    // }
    // // 当前窗口token存在
    // else
    
    if (token) {
        if (fistLogin) {
            await Promise.all([store.dispatch("getUserInfo")])
            await store.dispatch("getUserModule")
            fistLogin = false
        }
        if (loginPaths.includes(from.path)) {
            // await Promise.all([store.dispatch("getUserInfo")])
            await store.dispatch("getUserModule")
        } 
        let routeMenu = store.state.routeMenu
        //去往登录/注册页 拦截并发送登录请求
        if (loginPaths.includes(to.path)) {
            let redirect = HomePath
            if (from.query.redirect && routeMenu.includes(from.query.redirect)) {
                redirect = decodeURIComponent(decodeURIComponent(from.query.redirect))
            }
            next({
                path: redirect,
                replace: true,
            })
        } else if (to.path === HomePath && from.query.redirect && from.query.redirect != HomePath) {
            next({
                path: decodeURIComponent(decodeURIComponent(from.query.redirect)),
            })
        } else {
            if (routeMenu.length && routeMenu.includes(HomePath) && !routeMenu.includes(to.path)) {
                MessageBox.alert("你没有该页面相关权限", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: true,
                    customClass: "labMessageClass",
                })
                console.log("路由权限拦截",routeMenu, to.path, routeMenu.includes(HomePath), routeMenu.includes(to.path))
                router.push({
                    path: HomePath,
                })
            } else {
            next()
            }
        }
    }
    // 当前窗口token不存在
    else {
        if (loginPaths.includes(to.path)) {
            fistLogin = true
            if (isElectron) {
                ipcRenderer.send("setLoginWindow")
                next()
            } else {
                next()
            }
        } else {
            fistLogin = true
            next({
                path: LoginPath,
                query: {
                    redirect: to.fullPath,
                },
            })
        }
    }
})

window.addEventListener("offline", () => {
    console.log("网络断开")
    router.push({
        path: OfflinePath,
    })
})
window.addEventListener("online", () => {
    /** 网络连接时，需要重定向的路由 */
    let redirect = router.currentRoute.query.redirect
    if (redirect) {
        redirect = decodeURIComponent(decodeURIComponent(redirect))
    } else {
        redirect = "/"
    }
    console.log("网络连接", redirect)
    router.push({
        path: redirect,
    })
})
export default router
// export const routerMenu = routes[0].children[0].children.filter(item => item.meta.showInSideMenu)
export {
    routes,
    // BusinessDataRoutes,
    HomePath,
    OfflinePath,
}

import Vue from "vue"
import Vuex from "vuex"
import router from "@/router"
import api from "@/api/api"
import { getLocalToken,removeTokenAndRedirect, removeLocalToken, setLocalToken } from "@/core/token"
import { isElectron } from "@/core/Electron"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {
            id: "",
            userName: "",
            tenantId: "",
            mobileNumber: "",
            roleList: "",
            memberId: "",
            realName: "",
            isAdmin: "",
            orgName: "",
            orgId: "",
        },
        productInfo: {
            productCode: "YunBusiness",
        },
        /**用户导航及按钮权限 */
        productModuleTreeList: [],
        productModuleCode: [
            // 资源
            'YunBusiness:testOrg:save',"YunBusiness:device:save",
            "YunBusiness:device:delete",
            "YunBusiness:device:export",
            "YunBusiness:device:import",
            "YunBusiness:staff:save",
            "YunBusiness:staff:export",
            "YunBusiness:staff:import",
            "YunBusiness:testBasis:save",
            "YunBusiness:testBasis:delete",
            "YunBusiness:testBasis:export",
            "YunBusiness:testBasis:import",
            'YunBusiness:scheme:save',
            'YunBusiness:scheme:paula',
            'YunBusiness:testObject:save',
            'YunBusiness:testObject:delete',
            'YunBusiness:testObject:export',
            'YunBusiness:testObject:import',
            // 任务
            "YunBusiness:task:save",
            "YunBusiness:mytask:refresh",
            //委托
            "YunBusiness:entrust:save",
            "YunBusiness:entrust:refresh",
            "YunBusiness:sample:save",
            "YunBusiness:sample:print",
            "YunBusiness:entrusts:save",
            "YunBusiness:entrusts:select",
            "YunBusiness:entrusts:paula",
            "YunBusiness:entrusts:refresh",
            "YunBusiness:entrusts:printer",
            "YunBusiness:entrusts:print",
            //报告
            'YunBusiness:sign:start',
            'YunBusiness:change:save',
            'YunBusiness:change:delete',
            'YunBusiness:send:batch',
            'YunBusiness:library:batch',
        ],
        /**路由 */
        routeMenu: [],
        // 字典
        dataDictionary: {
            // 归属表单
            formAttr: [
                { label: "委托表单", value: "01" },
                { label: "任务表单", value: "02" },
                { label: "样品表单", value: "03" },
            ],
            // 表单状态
            formStatus: [
                {
                    label: "发布",
                    value: "01",
                    style: {
                        color: "#058E44",
                    },
                },
                {
                    label: "作废",
                    value: "02",
                    style: {
                        color: "#D9001B",
                    },
                },
                {
                    label: "草稿",
                    value: "03",
                    style: {
                        color: "#E6D540",
                    },
                },
            ],
            /** 模板管理-模板库  模板类型 */
            templateType: [
                { label: "通用模板", value: "01" },
                { label: "项目模板", value: "02" },
                { label: "其他模板", value: "03" },
                { label: "文档结构", value: "04" },
            ],
            /** 模板管理-模板库  模板状态 */
            templateStatus: [
                { label: "发布", value: "01", style: { color: "#058E44" } },
                { label: "草稿", value: "02", style: { color: "#E6D540" } },
                { label: "作废", value: "03", style: { color: "#D9001B" } },
            ],
        },
        /** 窗口是否最大化 应用在Electron环境中 */
        isMaximized: false,
        /** 是否从统一认证过来 true为是 false为本系统登录 */
        isMentWeb: false,
    },
    mutations: {
        /**
         * @description 登录之后设置当前窗口和本地token
         */
        setToken(state, token) {
            let Token = token

            // state.token = token;
            setLocalToken(Token)
        },
        setAdmin(state, data) {
            this.state.isAdmin = data
        },
        setUserInfo(state, userInfo) {
            let { token, ...params } = userInfo
            // let { id, isAdmin, tenantId } = params;
            state.userInfo = {
                ...(params || {}),
            }
        },
        /** electron环境下 窗口最大化 或者 还原 */
        setIsMaximized(state, isMaximized) {
            // console.log('setIsMaximized', isMaximized);
            state.isMaximized = isMaximized
        },
        setMentWeb(state, isMentWeb) {
            this.state.isMentWeb = isMentWeb
        },
        setMenuButton(state, MenuButton) {
            state.productModuleTreeList = MenuButton.productModuleTreeList
            state.productModuleCode = MenuButton.productModuleCode || []
            state.routeMenu = MenuButton.moduleUrlList || []
        },
    },
    actions: {
        async getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                api.getLoginAPI({ productCode: "YunBusiness" }, "/tenant/getLoginUser")
                    .then(async res => {
                        // commit("YunBusiness", res.data.data.productIds)
                        commit("setUserInfo", res.data.data)
                        resolve()
                    })
                    .catch(error => {
                        console.log("error", error)
                        resolve()
                    })
            })
        },
        /**获取用户权限 */
        async getUserModule({ commit }) {
            return new Promise((resolve, reject) => {
                api.postLoginAPI(
                    {
                        userProductIds: this.state.userInfo.productIds,
                    },
                    "/tenant/queryLoginUserModule"
                )
                    .then(res => {
                        commit("setMenuButton", res.data.data)
                        resolve()
                    })
                    .catch(error => {
                        console.log("error", error)
                        resolve()
                    })
            })
        },
        async logout({ commit }) {
            return new Promise((resolve, reject) => {
                if (this.state.isMentWeb) {
                    removeTokenAndRedirect()
                } else {
                    removeTokenAndRedirect()
                    api.getLoginAPI("", "/sso/signout", "", {
                        showSuccessMessage: true,
                    })
                }
            })
        },
    },
    modules: {},
})

import Vue from "vue"
import productEntrustView from "product-yun-entrust-view";
// import productEntrustView from "../../../../product-yun-entrust-view";
Vue.use(productEntrustView);


import { entrustHome,entrustHomeDetails,sampleLog,customersContacts,contractManagement } from "product-yun-entrust-view";
// import { entrustHome,entrustHomeDetails,sampleLog,customersContacts,contractManagement } from "../../../../product-yun-entrust-view";

import productTaskViewRoutes from "./product-yun-task-view"
/** 资源 */
const routes = [
    {
		path: "/entrustHome",
		name: "entrustHome",
		component: entrustHome,
		meta: {
			title: "试验委托",
			groupName: '委托',
			icon: "EntrustHome",
		},
	},
	{
		path: "/entrustHomeDetails",
		name: "entrustHomeDetails",
		component: entrustHomeDetails,
		meta: {
			hideMenu: true,
			currentActiveMenu: '/entrustHome'
		},
	},
    {
		path: "/sampleLog",
		name: "sampleLog",
		component: sampleLog,
		meta: {
			title: "样品登记",
            groupName: '委托',
			icon: "SampleLog",
		},
	},
	...productTaskViewRoutes,
	{
		path: "/customersContacts",
		name: "customersContacts",
		component: customersContacts,
		meta: {
			title: "客户和联系人",
			groupName: '客户',
			icon: "CustomersContacts",
		},
	},
	{
		path: "/contractManagement",
		name: "contractManagement",
		component: contractManagement ,
		meta: {
			title: "合同管理",
			groupName: '客户',
			icon: "ContractManagement",
		},
	},
]

export default routes
